<template>
  <div>
    <b-card no-body>
      <slot name="tableHeader" />
      <hr class="border-light m-0">
      <cp-controls
        v-if="!(withoutPagination && withoutSearch)"
        :without-pagination="withoutPagination"
        :without-search="withoutSearch"
        @onSearch="searchTable"
        @onChangeCountPages="changeTableLimit"
      />
      <div
        v-if="isMassSelectionAvailable"
        class="mx-4"
      >
        <b-alert
          :show="!massSelect && allSelect"
          variant="warning"
          class="text-center"
        >
          {{ $t('component.cpTable.message.numberSelected', [allSelectableItems]) }}
          <b-button
            variant="link"
            class="p-0 mass-selection-button"
            @click="handleMassSelect"
          >
            {{ $t('component.cpTable.button.checkbox.all', { totalItems: totalSelectableItems, tableTitle}) }}
          </b-button>
        </b-alert>

        <b-alert
          :show="massSelect && !excludedItems.length"
          variant="warning"
          class="text-center"
        >
          {{ $t('component.cpTable.message.clearAll', { totalItems: totalSelectableItems, tableTitle}) }}
          <b-button
            variant="link"
            class="p-0 mass-selection-button"
            @click="handleMassDeselect"
          >
            {{ $t('component.cpTable.button.checkbox.clear') }}
          </b-button>
        </b-alert>
      </div>

      <div
        ref="bTable"
        class="table-responsive text-keep-all"
      >
        <b-table
          hover
          striped
          show-empty
          no-sort-reset
          class="card-table"
          :class="[{ 'table-fixed': isFixed }]"
          :no-local-sorting="noLocalSorting"
          :fields="getFields()"
          :items="items"
          :per-page="queryParams.limit"
          :total-items="totalItems"
          :busy="!tableDataLoaded"
          :sort-by="defaultSortBy"
          :selectable="selectable"
          :select-mode="selectMode"
          :selected-variant="selectedVariant"
          :sort-desc="defaultSortDirection === 'desc'"
          :tbody-tr-class="rowsClickable ? 'cursor-pointer' : ''"
          @sort-changed="sortChange"
          @row-selected="onRowSelected"
          @row-clicked="onRowClicked"
        >
          <slot
            v-if="checkbox"
            slot="HEAD_checkbox"
          >
            <div
              class="custom-control custom-checkbox"
            >
              <input
                id="select-all-cb-0"
                v-model="allSelect"
                type="checkbox"
                class="custom-control-input"
                @click="onSelectAll"
              >
              <label
                for="select-all-cb-0"
                class="custom-control-label"
              />
            </div>
          </slot>
          <slot
            v-if="checkbox"
            slot="checkbox"
            slot-scope="{item}"
          >
            <b-form-checkbox
              v-model="selectedEntity[item.id]"
              :disabled="!item.id || item.isAggregatedBrokerDealerGroup"
              class="mr-0"
              @change="handleCheck($event, item)"
            />
          </slot>
          <slot
            v-for="key in slotsName"
            :slot="key"
            slot-scope="componentScope"
            :name="key"
            :row-data="componentScope"
          />
          <div
            slot="empty"
            class="text-center my-2"
          >
            <div
              :class="error ? 'row align-items-center mb-2 text-danger' : 'row align-items-center mb-2'"
              :style="minimumHeight"
            >
              <div class="col-md-12 text-center">
                {{ error ? `Error: ${error}` : noRecordsMessage }}
              </div>
            </div>
          </div>
          <div
            slot="table-busy"
            class="text-center my-2"
          >
            <div
              class="row align-items-center mb-2"
              :style="minimumHeight"
            >
              <div class="col-md-12 text-center">
                <b-spinner class="align-middle" />
                &nbsp;{{ $t('component.cpTable.message.loading') }}
              </div>
            </div>
          </div>
        </b-table>
      </div>
      <cp-pagination
        v-if="!withoutPagination"
        :total-items="totalItems"
        :per-page="queryParams.limit"
        :current-page="queryParams.page"
        @onInputPage="changeTablePage"
      />
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash';
import Cookie from 'js-cookie';
import { kebabCase } from 'change-case';
import cpTableSorting from '~/mixins/table-sorting';
import CpControls from '~/components/common/table/controls';
import CpPagination from '~/components/common/table/pagination';
import { i18nKeyListConvert } from '~/utilities/i18n-util';

export default {
  name: 'CpTable',
  layout: 'main',
  components: {
    CpPagination,
    CpControls,
  },
  mixins: [
    cpTableSorting,
  ],
  props: {
    defaultSortBy: {
      type: String,
      default: '',
    },
    defaultSortDirection: {
      type: String,
      default: 'desc',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    searchValue: {
      type: String,
      default: '',
    },
    withoutSearch: {
      type: Boolean,
      default: false,
    },
    withoutPagination: {
      type: Boolean,
      default: false,
    },
    noLocalSorting: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    dataParser: {
      type: Function,
      default: data => data,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: String,
      default: '', // 'multi', 'single', 'range'
    },
    selectedVariant: {
      type: String,
      default: '',
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    tableTitle: {
      type: String,
      default: 'test',
    },
    massSelectable: {
      type: Boolean,
      default: false,
    },
    getDataAction: {
      type: String,
      default: 'tableData/getData',
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    emptyTableMessage: {
      type: String,
      default: '',
    },
    i18nValues: {
      type: Array,
      default: () => [],
    },
    rowsClickable: {
      type: Boolean,
      default: false,
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      minHeight: 200,
      tableDataLoaded: false,
      totalItems: 0,
      totalSelectableItems: 0,
      error: '',
      allSelect: false,
      selectedEntity: {},
      selectedRowItems: [],
      selectedItems: [],
      excludedItems: [],
      queryParams: {
        q: this.searchValue || undefined,
        page: 0,
        limit: parseInt(Cookie.get('perPage')) || 25,
        'order-direction': this.defaultSortDirection,
        'order-field': kebabCase(this.defaultSortBy) || undefined,
      },
      massSelect: false,
    };
  },
  computed: {
    updateTableData() {
      return _.debounce(() => this._updateTableData(), 500);
    },
    minimumHeight() {
      return `min-height: ${this.minHeight - 37}px;`;
    },
    slotsName() {
      return Object.keys(this.$scopedSlots);
    },
    joinTableParams() {
      const data = {
        ...this.queryParams,
        ...this.filters,
      };

      // this cleaning is necessary for the filters.
      Object.keys(data)
        .forEach((field) => {
          if (data[field] === '') {
            delete data[field];
          }
        });

      return data;
    },
    isMassSelectionAvailable() {
      return this.massSelectable && (this.totalItems > this.queryParams.limit);
    },
    selectedItemsCount() {
      if (this.massSelect) {
        return this.totalSelectableItems - this.excludedItems.length;
      }
      return this.selectedItems.filter(item => !item.isAggregatedBrokerDealerGroup).length;
    },
    noRecordsMessage() {
      return this.emptyTableMessage || this.$t('component.cpTable.message.noRecords');
    },
    allSelectableItems() {
      return this.items.filter(item => !item.isAggregatedBrokerDealerGroup).length;
    },
  },
  watch: {
    filters() {
      this.handleMassDeselect();
      this.updateTableData();
    },
    searchValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleMassDeselect();
        this.queryParams.q = newValue;
        this.updateTableData();
      }
    },
    urlParams: {
      deep: true,
      handler() {
        this._updateTableData();
      },
    },
  },
  async created() {
    if (!this.withoutSearch && this.$route.query.search) {
      this.queryParams.q = this.$route.query.search;
    }
    this._updateTableData();
  },

  methods: {
    changeTablePage(page = this.queryParams.page) {
      this.queryParams.page = page - 1;
      this.$router.replace({ query: { ...this.$route.query, page: decodeURIComponent(page) } });
      this.updateTableData();
    },
    changeTableLimit(limit = this.queryParams.limit) {
      this.queryParams.limit = limit;
      this.updateTableData();
    },
    searchTable(q = this.queryParams.q) {
      if (q !== this.queryParams.q) {
        this.queryParams.q = q;
        this.queryParams.page = 0;
        this.updateTableData();
      }
    },
    getTableData() {
      return [...(this.items || [])];
    },
    getFields() {
      return i18nKeyListConvert(this.fields, 'label', this.i18nValues);
    },
    sortChange(data) {
      const orderField = data.sortBy || this.queryParams['order-field'];
      if (orderField) {
        this.queryParams['order-direction'] = !data.sortDesc ? 'asc' : 'desc';
        this.queryParams['order-field'] = kebabCase(orderField);
        this.$router.replace({
          query: {
              ...this.$route.query,
              'order-direction': this.queryParams['order-direction'],
              'order-field': this.queryParams['order-field'],
            },
          });
      } else {
        delete this.queryParams['order-direction'];
        delete this.queryParams['order-field'];
        delete this.$route.query['order-direction'];
        delete this.$route.query['order-field'];
        this.$router.replace({ query: { ...this.$route.query } });
      }
      this.updateTableData();
    },
    onRowSelected(items) {
      this.selectedRowItems = items;
      if (this.selectable) {
        this.$emit('onRowSelected', this.selectedRowItems);
      }
    },
    _updateTableData() {
      const bTable = this.$refs && this.$refs.bTable;

      if (bTable) {
        const tBody = bTable.querySelector('tbody');
        this.minHeight = (tBody && tBody.clientHeight) || 200;
      }
      this.error = '';
      this.tableDataLoaded = false;
      const getDataConfig = {
        params: {
          ...(this.queryParams || {}),
          ...(this.filters || {}),
          ...(this.urlParams || {}),
        },
      };
      this.$store.dispatch(this.getDataAction, getDataConfig)
        .then((res) => {
          const { data, totalItems } = res || {};
          if (data) {
            this.totalItems = data.totalItems || totalItems || 0;
            this.totalSelectableItems = data.totalSelectableItems || 0;
            this.items = this.dataParser(data.data || data);
          }
        })
        .catch(
          (e) => {
            this.totalItems = 0;
            this.items = [];
            this.totalSelectableItems = 0;
            this.error = (e && e.response && e.response.data && e.response.data.message) || this.$t('component.cpTable.message.error');
          },
        )
        .finally(
          () => {
            this.minHeight = 200;
            this.tableLoadedActions();
            this.$emit('tableDataUpdated', {
              items: this.items || [],
              totalItems: this.totalItems || 0,
              error: this.error || null,
              selectedItemsCount: this.selectedItemsCount,
            });
          },
        );
    },
    onSelectAll() {
      this.allSelect = !this.allSelect;
      if (this.massSelect && !this.allSelect) {
        this.handleMassDeselect();
      } else {
        this.massSelect = false;
        this.selectedItems = this.allSelect ? this.filterTableDataById(this.getTableData()) : (this.handleMassDeselect());
      }
      this.$emit('onSelectAll', {
        allSelect: this.allSelect,
        selectedItemsCount: this.selectedItemsCount,
        selectedItems: this.selectedItems,
      });
    },
    filterTableDataById(data) {
      return data.filter((item) => {
        if (item.id) {
          this.$set(this.selectedEntity, item.id, this.allSelect);
          return true;
        }
        return false;
      });
    },
    clearSelection() {
      this.selectedEntity = {};
      this.selectedItems = [];
      this.excludedItems = [];
      this.allSelect = false;
      this.massSelect = false;
    },
    tableLoadedActions() {
      this.$emit('tableDataUpdated', this.tableDataLoaded);
      if (this.massSelect) {
        this.items.forEach((item) => {
          if (this.selectedEntity[item.id] === undefined) {
            this.selectedEntity[item.id] = true;
          }
        });
      } else {
        this.clearSelection();
      }
      this.tableDataLoaded = true;
    },
    handleMassSelect() {
      this.massSelect = true;
      this.selectedItems = [];
      this.excludedItems = [];
      this.$emit('onMassSelect', {
        massSelect: this.massSelect,
        selectedItemsCount: this.selectedItemsCount,
        selectedItems: this.selectedItems.filter(item => !item.isAggregatedBrokerDealerGroup),
      });
    },
    handleMassDeselect() {
      this.selectedEntity = {};
      this.selectedItems = [];
      this.excludedItems = [];
      this.allSelect = false;
      this.massSelect = false;
      this.$emit('onMassSelect', {
        massSelect: this.massSelect,
        selectedItemsCount: this.selectedItemsCount,
        selectedItems: this.selectedItems,
      });
      return [];
    },
    handleCheck(value, item) {
      this.allSelect = false;
      if (this.massSelect) {
        if (value) {
          this.excludedItems = this.excludedItems.filter(data => data.id !== item.id);
        } else {
          this.excludedItems = [item, ...this.excludedItems];
        }
      } else if (value) {
        this.selectedItems = [item, ...this.selectedItems];
      } else {
        this.selectedItems = this.selectedItems.filter(data => data.id !== item.id);
      }
      this.$emit('onSelect', {
        value,
        item,
        selectedItemsCount: this.selectedItemsCount,
      });
    },
    getSelectedItems() {
      return {
        selectedItems: this.selectedItems,
        excludedItems: this.excludedItems,
      };
    },
    onRowClicked(data, i, e) {
      if (this.rowsClickable) this.$emit('rowClicked', { data, e });
    },
  },

};
</script>

<style scoped lang="scss">
  .text-keep-all {
    word-break: keep-all;
    white-space: pre-line;
  }

  .align-to-middle {
    padding-left: 25%;
  }

  .table-responsive {
    position: relative;
    overflow-y: hidden;
    min-height: 200px;

    .table-load-text {
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  .ion-ios-download {
    vertical-align: text-bottom;
  }

  .edit-operator-btn {
    color: #04bec4;
  }

  .mass-selection-button {
    text-decoration: underline;
  }
</style>
