<template>
  <b-card-body>
    <div class="row">
      <i18n
        v-if="!withoutPagination"
        path="component.cpTableControls.label.show"
        tag="div"
        class="col"
      >
        <b-select
          v-model="countPerPage"
          size="sm"
          :options="[10, 25, 50, 100]"
          class="d-inline-block w-auto"
        />
      </i18n>
      <div
        v-if="!withoutSearch"
        class="col"
      >
        <b-input
          v-model="search"
          size="sm"
          :placeholder="$t('common.placeholder.search')"
          class="d-inline-block w-auto float-sm-right"
        />
      </div>
    </div>
  </b-card-body>
</template>

<script>
import _ from 'lodash';
import Cookie from 'js-cookie';

export default {
  name: 'CpTableControls',
  props: {
    noDebounce: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
    withoutSearch: {
      type: Boolean,
      default: false,
    },
    withoutPagination: {
      type: Boolean,
      default: false,
    },
    controlledSearch: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      stateSearch: '',
    };
  },
  computed: {
    countPerPage: {
      get() {
        return parseInt(Cookie.get('perPage')) || 25;
      },
      set(newCount) {
        Cookie.set('perPage', newCount);
        this.$emit('onChangeCountPages', newCount);
      },
    },
    search: {
      get() {
        return this.controlledSearch || this.$route.query.search || '';
      },
      set(search) {
        this.stateSearch = search;
        if (this.noDebounce) {
          this.$emit('onSearch', search);
          this.$router.replace({ query: { ...this.$route.query, search: search.trim() } });
        } else {
          this.debouncedGetAnswer();
        }
      },
    },
  },
  methods: {
    debouncedGetAnswer: _.debounce(function () { // eslint-disable-line func-names
      const { search, ...restQuery } = this.$route.query;
      this.controlledSearch = this.stateSearch.trim();
      this.$emit('onSearch', this.controlledSearch);
      if (this.controlledSearch) {
        Object.assign(restQuery, { search: this.controlledSearch });
      }
      this.$router.replace({ query: { ...restQuery } });
    }, 2000),
  },
};
</script>
